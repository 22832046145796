import * as React from 'react'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import { MenuItems } from '../models/MenuItems'
import MainMenu from '../components/MainMenu/MainMenu'
import '../styles/_fonts.scss'
import classNames from 'classnames'
import styles from './styles.module.scss'
interface StaticQueryProps {
  site: {
    siteMetadata: {
      title: string
      description: string
    }
  }
  wordpressMenusMenusItems: {
    items: Array<MenuItems>
  }
}

interface IndexLayoutProps {
  background?: 'grey' | 'black' | 'none'
  maxWidthWrapper?: boolean
}

const IndexLayout: React.FC<IndexLayoutProps> = ({ children, background = 'none', maxWidthWrapper = false }) => {
  const classes = classNames(styles[background], { [styles.maxWidthWrapper]: maxWidthWrapper })
  const logo = 'https://klimatbutiken.accomplice-dev.se/wp-content/uploads/2021/03/klimatbutiken.png'
  return (
    <StaticQuery
      query={graphql`
        query IndexLayoutQuery {
          site {
            siteMetadata {
              title
              description
            }
          }
          wordpressMenusMenusItems {
            items {
              slug
              title
            }
          }
        }
      `}
      render={(data: StaticQueryProps) => (
        <>
          <Helmet title={data.site.siteMetadata.title} htmlAttributes={{ lang: 'sv' }}>
            <meta property="og:image" content={logo} />
            <meta property="og:image" content={logo} />
            <meta property="og:url" content="https://klimatbutiken.com/" />
          </Helmet>
          <MainMenu menuItems={data.wordpressMenusMenusItems.items} />
          <main className={classes}>{children}</main>
        </>
      )}
    />
  )
}

export default IndexLayout
