import React from 'react'
import { useState, useContext } from 'react'
import styles from './styles.module.scss'
import { MenuItems } from '../../models/MenuItems'
import { Icon, KlimatbutikenLogo, Shoppingcart } from '../Icon'
import { navigate, Link } from 'gatsby'
import { StoreContext } from '../../store/context'

interface MainMenuProps {
  menuItems: Array<MenuItems>
}

const MainMenu: React.FC<MainMenuProps> = ({ menuItems }) => {
  const [showMenu, setShowMenu] = useState(false)

  const handleClick = () => {
    return setShowMenu(!showMenu)
  }

  const renderMenuItems = menuItems.map((item) => {
    const { slug, title } = item
    return (
      <li key={title}>
        <Link to={slug === 'start' ? '/' : `/${slug}`}>
          <span className={styles.menuItem}>{item.title}</span>
        </Link>
      </li>
    )
  })

  const navigateToCart = () => navigate('/varukorg')

  const {
    state: {
      shoppingCart: { totalQuantityOfProducts }
    }
  } = useContext(StoreContext)

  return (
    <nav className={styles.nav} aria-labelledby="main-menu">
      <h2 id="main-menu" className={styles.hidden}>
        Main Menu
      </h2>
      <div className={styles.topMenu}>
        <button onClick={handleClick} className={styles.menuToggle} aria-controls="primary-menu" aria-expanded={showMenu}>
          <span className={styles.hidden}>Show menu</span>
          <span></span>
          <span></span>
          <span></span>
        </button>
        <Link to="/">
          <Icon className={styles.logo} icon={KlimatbutikenLogo} size="none" />
        </Link>
        <div className={styles.cartWrapper}>
          <Icon className={styles.cart} icon={Shoppingcart} size="large" onClick={navigateToCart} />
          <span className={styles.cartAmount}>{totalQuantityOfProducts}</span>
        </div>
      </div>
      {showMenu && <ol className={styles.menuList}>{renderMenuItems}</ol>}
    </nav>
  )
}

export default React.memo(MainMenu)
