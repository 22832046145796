import * as React from 'react'
import { Link } from 'gatsby'
import { useTranslation } from 'react-i18next'
import IndexLayout from '../layouts'

const NotFoundPage: React.FC = () => {
  const { t } = useTranslation()
  return (
    <IndexLayout>
      <h1 className="error" style={{ paddingTop: '10rem', textAlign: 'center' }}>
        {t('404.msg')}
      </h1>
      <p className="error" style={{ paddingTop: '4rem', textAlign: 'center' }}>
        <Link to="/">{t('404.back')}</Link>
      </p>
    </IndexLayout>
  )
}

export default NotFoundPage
